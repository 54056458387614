@import url('./markdown.less');
@import url('./highlight.less');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
